import React from 'react'
import App from './App'
import PrivacyPolicy from './PrivacyPolicy'
import TermsAndConditions from './TermsAndConditions'
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

function Paths() {
  return (
  <Router>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
    </Routes>
  </Router>
  )}

export default Paths