import React, { useState } from 'react';
import { 
    Button,
    FormControl,
    CircularProgress
} from '@mui/material';
import { useSnackbar } from 'notistack';

import FileUploader from './FileUploader';
import CommentForm from './CommentForm';

import { COMMENT_SERVER_URL, FILE_SERVER_URL } from '../config'

const UploadForm = (userEmail) => {
    const [note, setNote] = useState("");
    const [noteInputError, setNoteInputError] = useState(false);
    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const fileChangeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    }

    const handleNoteUploadSuccess = () => {
        const noteInput = document.getElementById('note-form-input');
        noteInput.value = ''
        setNote('')
        enqueueSnackbar('Note Uploaded Successfully',{
            variant: 'success'
        })
    }

    const handleNoteUploadError = () => {
        setNoteInputError(true)
        enqueueSnackbar('Oops. There was a problem uploading your note',{
            variant: 'error'
        })
    }

    const handleFileUploadSuccess = () => {
        setSelectedFile()
        setIsFilePicked(false)
        enqueueSnackbar('File Uploaded Successfully',{
            variant: 'success'
        });
        setIsLoading(false);
    }

    const handleFileUploadError = () => {
        enqueueSnackbar('There was a problem uploading your image',{
            variant: 'error'
        });
        setIsLoading(false);
    }

    const uploadFile = () => {
        setIsLoading(true)
        const formData = new FormData();
        formData.append('File', selectedFile);
        formData.append('user', userEmail.userEmail);

        fetch(
            FILE_SERVER_URL,
            {
                method: 'POST',
                body: formData,
            }
            )
            .then((response) => {
                return response.json()
            })
            .then((result) => {
                console.log('Success: ', result);
                handleFileUploadSuccess();
                if (note.length > 0) {
                    uploadComment(result.gsheetid);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                handleFileUploadError();
            });
        }

    const uploadComment = (gsheetId) => {
        setIsLoading(true)
        console.log('A note was submitted: ' + note);
        const formData = {
            user: userEmail.userEmail,
            comment: note,
            filename: ' ',
            gsheet_id: gsheetId
        }
        if (isFilePicked) {
            formData.filename = selectedFile.name;
        }
        fetch(
            COMMENT_SERVER_URL,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            }
        )
        .then((response) => {
            console.log('got a response');
            console.log(response);
            return response.json()
        })
        .then((result) => {
            console.log('Success: ', result);
            handleNoteUploadSuccess();
            setIsLoading(false);
        })
        .catch((error) => {
            console.error('Error:', error);
            handleNoteUploadError();
            setIsLoading(false);
        });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        uploadFile();
    }

    return (
        <FormControl sx={{
                width: '90%'
                }}>
            <FileUploader 
                fileChangeHandler={fileChangeHandler} 
                selectedFile={selectedFile} 
            />
            <br />
            <CommentForm 
                error={noteInputError} 
                handleCommentChange={(e) => setNote(e.target.value)}
            />
            {((isFilePicked === false && note === '') || (isLoading)) 
            ? 
                <Button 
                    type="submit" 
                    value="Submit"
                    onClick={handleSubmit}
                    variant="contained"
                    disabled
                > 
                {(isLoading === false) ? 'Submit' : <CircularProgress color="inherit"/>}
                </Button> 
            :
                <Button 
                    type="submit" 
                    value="Submit"
                    onClick={handleSubmit}
                    variant="contained"
                > 
                Submit
                </Button>
            }
        </FormControl>
    );
};

export default UploadForm