import React from 'react'
import LoginButton from './LoginButton';
import LogoutButton from './LogoutButton';
import { useAuth0 } from '@auth0/auth0-react';
import {
    AppBar,
    Toolbar
    } from '@mui/material';


function NavBar() {
  const { user, isLoading } = useAuth0();
    return (
        <AppBar 
            position="static" 
            sx={{
                backgroundColor: '#add9f4',
                top:'0'
            }}
        >
            <Toolbar
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '16px'
                }}
            >
                <img className="logo" src="../../low_carbon_beef.png" alt="LCB Logo" height="100px"/>
                <nav className="login-nav">
                    {isLoading && (
                        <p>Loading Auth0...</p>
                        )}
                    {!isLoading && !user && (
                        <LoginButton />
                        )}
                    {!isLoading && user && (
                        <LogoutButton />
                        )}
                </nav>
            </Toolbar>
        </AppBar>
    );
};

export default NavBar;
