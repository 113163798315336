import React from 'react'
import { 
        Button,
        FormControl,
        Typography
    } from '@mui/material';

function FileUploader(props) {
    const changeHandler = props.fileChangeHandler;

    return (
        <FormControl
            sx={{
                alignItems: 'center'
            }}
        >
            <Button
                variant="outlined"
                component="label"
            >
            Choose File
            <input
                type="file"
                hidden
                onChange={changeHandler}
            />
            </Button>
            {props.selectedFile &&
                <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
                    {props.selectedFile.name}
                </Typography>
            }
        </FormControl>
    );
};

export default FileUploader;
