import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Button, Typography } from '@mui/material'
import LoginIcon from '@mui/icons-material/Login';

function LoginButton() {
    const { loginWithRedirect } = useAuth0();
    return (
        <Button 
            variant="text"
            onClick={() => loginWithRedirect()}
            sx={{
                borderColor: 'black',
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            <LoginIcon
                fontSize="large"
                sx={{
                    fill: 'black'
                }}
            />
            <Typography
                sx={{
                    color: 'black'
                }}
            >
                LOG IN
            </Typography>
        </Button>
    );
}

export default LoginButton;
