import React from 'react'
import { 
        FormControl,
        TextField,
        Typography
    } from '@mui/material';

function CommentForm(props) {
    const handleChange = props.handleCommentChange;
    return (
        <div>
            <Typography variant="body1" className={'note-form__label'}>
                Add a note (optional):
            </Typography>
            <FormControl fullWidth sx={{ m: 1 }} onChange={handleChange} className={'note-form__input'}>
                <TextField
                    error={props.error}
                    id="note-form-input"
                    onChange={handleChange}
                    label="Note"
                    variant="filled"
                    multiline
                />
            </FormControl>
        </div>
    );
};

export default CommentForm
