import React from 'react'
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Typography } from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout';

function LogoutButton() {
    const { logout } = useAuth0();
    return (
        <Button
            variant="text"
            onClick={() => logout({
                returnTo: window.location.origin
            })}
            sx={{
                borderColor: 'black',
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            <LogoutIcon
                fontSize="large"
                sx={{
                    fill: 'black'
                }}
            />
            <Typography
                sx={{
                    color: 'black'
                }}
            >
                LOG OUT
            </Typography>
        </Button>
    )
}

export default LogoutButton;
