import './App.css';
import NavBar from './components/NavBar';
import UploadForm from './components/UploadForm'
import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Card, CardHeader, CardContent, Button } from '@mui/material';
import { SnackbarProvider } from 'notistack';

let deferredPrompt ;

function App() {
  const { user } = useAuth0();
  const [installable, setInstallabe] = useState(false);
  const handleInstallClick = (e) => {
    deferredPrompt.prompt();
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult === 'accepted') {
        console.log('User accepted');
      } else {
        console.log('User dismissed');
      }
    });
  }

  useEffect (() => {
    window.addEventListener('beforeinstallprompt', async (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      deferredPrompt = e;
      // Update UI notify the user they can install the PWA
      setInstallabe(true);
      // Send analytics event that PWA install promo was shown.
      console.log(`'beforeinstallprompt' event was fired.`);
    });

    window.addEventListener('appinstalled', () => {
      // Hide the app-provided install promotion
      setInstallabe(false);
      // Clear the deferredPrompt so it can be garbage collected
      deferredPrompt = null;
      // Optionally, send analytics event to indicate successful install
      console.log('PWA was installed');
    });
  }, [user])

  return (
    <SnackbarProvider
      dense={true}
    >
    <div className="App">
        <NavBar />
      { user && 
      <Card
        sx={{
          width: '95%',
          marginTop: '5vw',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          border: '1px solid black'
        }}
        raised={true}
      >
        <CardHeader 
        title="LOWERING THE CARBON FOOTPRINT OF BEEF"
          sx={{
            backgroundColor: '#0fb350',
            width: '100%',
            color: 'white'
          }}
        >
        </CardHeader>
        <CardContent
          sx={{
            backgroundColor: '#fffff0',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <UploadForm userEmail={user.email}/>
        </CardContent>
      </Card>
      }

      {!user &&
        <h2>
          Please Log In to use this app.
        </h2>
      }

      { installable &&
      <div>
        <h2>
          Try downloading the app!
        </h2>
        <Button variant="contained" onClick={handleInstallClick}>Install The App</Button>
      </div>
      }
    </div>
    </SnackbarProvider>
  );
}

export default App;
